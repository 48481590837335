.footer {
  padding: 20px 0;
  background-color: #333;
  color: #fff;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  p {
    margin: 10px 0;
  }
}
