.features {
  padding: 80px 0;
  background-color: #f9f9f9;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .title {
    text-align: center;
    margin-bottom: 60px;
    font-size: 2rem;
  }

  .featuresGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }

  .featureItem {
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;

    h4 {
      margin-bottom: 15px;
      font-size: 1.25rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}
