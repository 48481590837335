.callToAction {
  background-color: #2575fc;
  color: white;
  padding: 60px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .title {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  .ctaButton {
    background-color: #fff;
    color: #2575fc;
    padding: 15px 30px;
    font-size: 1.25rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e4e4e4;
    }
  }
}
