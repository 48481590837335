.howItWorks {
  padding: 80px 0;
  background-color: #fff;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .title {
    text-align: center;
    margin-bottom: 60px;
    font-size: 2rem;
  }

  .stepsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }

  .step {
    flex-basis: 220px;
    text-align: center;

    .stepNumber {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #6a11cb;
      color: #fff;
      line-height: 50px;
      margin-bottom: 15px;
      font-size: 1.25rem;
    }

    h4 {
      margin-bottom: 10px;
      font-size: 1.25rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}
