.header {
  background-color: #f0f4f8;
  padding: 20px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;

    h1 {
      margin: 0;
      color: #333;
      font-size: 2.5rem;
    }

    p {
      margin-top: 10px;
      color: #666;
      font-size: 1.25rem;
    }
  }
}
