.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  padding: 0 15px;

  .container {
    max-width: 600px;

    h1 {
      margin-bottom: 20px;
      font-size: 2.5rem;
    }

    p {
      margin-bottom: 30px;
      font-size: 1.25rem;
    }

    .homeLink {
      background-color: #007bff;
      color: #ffffff;
      padding: 10px 20px;
      border-radius: 5px;
      text-decoration: none;
      font-size: 1rem;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
