.hero {
  background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
  color: white;
  padding: 60px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.5;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .ctaButton {
    background-color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    border-radius: 4px;
    &:hover {
      background-color: #e4e4e4;
    }
  }
}
